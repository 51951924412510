import React from "react";

import Navigation from "../../leadership/BioNavigationBar";
import DiHero from "../diHero/DiHero";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import SectionFour from "./SectionFour";

const DiMobileContainer = () => {
  return (
    <div>
      <Navigation />
      <DiHero />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
    </div>
  );
};

export default DiMobileContainer;
