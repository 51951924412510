import React from "react";
import { Link } from "react-router-dom";

import heroImg from "../../assets/images/SLIDE5.jpg";

import BrandonImg from "../../assets/images/BRANDON500X500.jpg";
import scottImg from "../../assets/images/SCOTTR500X500.jpg";
import JimImg from "../../assets/images/JIM500X500.jpg";
import BryanImg from "../../assets/images/BRYAN500x500.jpg";
import ChrisImg from "../../assets/images/CHRIS500X500.jpg";
import ErikImg from "../../assets/images/ERIK500X500.jpg";
import JerryImg from "../../assets/images/JERRY500X500.jpg";
import KenImg from "../../assets/images/KEN500X500.jpg";
import PatImg from "../../assets/images/PAT500X500.jpg";
import RobImg from "../../assets/images/ROB500X500.jpg";
import KarenImg from "../../assets/images/karen-siu.jpg";
import ToddImg from "../../assets/images/TODD500X500.jpg";
import ScottJImg from "../../assets/images/SCOTTJ500X500.jpg";
import ScottCImg from "../../assets/images/ScottCorkery_500x500.jpg";
import ValImg from "../../assets/images/Val.jpg";
import JimK from "../../assets/images/JIMKEARNY.jpg";
import MattS from "../../assets/images/MattSimmavong.jpg";
import ChristineN from "../../assets/images/ChristineNguyen.jpg";
import BrandonTImg from "../../assets/images/BrandonTse.jpg";
import BrianEImg from "../../assets/images/BrianEng.jpg";
import robertMImg from "../../assets/images/RobertMoretta.jpg";
import RyanImg from "../../assets/images/RyanWhite.jpg";

import "./Leadership.css";

const Leadership = (props) => {
  return (
    <>
      <div className="leadership_main_container" ref={props.refProp} id="team">
        {/* <div className="leadership_image_wrapper"></div> */}
        <img src={heroImg} alt="hero" className="leadership_hero_img" />
        <h1 className="text_center margin_bottom">TEAM</h1>
        <div className="leadership_content">
          {/* row start */}
          <div className="team-row">
            {/* card start */}
            <div className="team-card">
              <Link to="/jimcondon">
                <div className="team-card-img-container">
                  <img src={JimImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">
                    JIM CONDON, MBA, CIMA ®
                  </h6>
                  <p className="no-margin-bottom">
                    MANAGING PARTNER, STRATEGIC CAPITAL MANAGEMENT HOLDINGS, LLC
                  </p>
                  <p className="no-margin-bottom">
                    PRESIDENT, STRATEGIC CAPITAL FUND MANAGEMENT
                  </p>
                </div>
              </Link>
            </div>
            {/* card end */}
            {/* card start */}
            <div className="team-card">
              <Link to="/patmiller">
                <div className="team-card-img-container">
                  <img src={PatImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">
                    PATRICK MILLER
                  </h6>
                  <p className="no-margin-bottom">
                    MANAGING PARTNER, STRATEGIC CAPITAL MANAGEMENT HOLDINGS, LLC
                  </p>
                </div>
              </Link>
            </div>
            {/* card end */}
            {/* card start */}
            <div className="team-card">
              <Link to="/kenjaffe">
                <div className="team-card-img-container">
                  <img src={KenImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">KENNETH JAFFE</h6>
                  <p className="no-margin-bottom">
                    MANAGING PARTNER, STRATEGIC CAPITAL MANAGEMENT HOLDINGS, LLC
                  </p>
                </div>
              </Link>
            </div>
            {/* card end */}
          </div>
          {/* row end */}

          {/* row start */}
          <div className="team-row">
            {/* card start */}
            <div className="team-card">
              <Link to="/scottcorkey">
                <div className="team-card-img-container">
                  <img src={ScottCImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">
                    SCOTT W. CORKERY, CPA, CA
                  </h6>
                  <p className="no-margin-bottom">CHIEF FINANCIAL OFFICER</p>
                  <p className="no-margin-bottom">
                    STRATEGIC CAPITAL FUND MANAGEMENT
                  </p>
                </div>
              </Link>
            </div>
            {/* card end */}
            {/* card start */}
            <div className="team-card">
              <Link to="/erikrostvold">
                <div className="team-card-img-container">
                  <img src={ErikImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">ERIK ROSTVOLD</h6>
                  <p className="no-margin-bottom">SENIOR VICE PRESIDENT</p>
                  <p className="no-margin-bottom">
                    STRATEGIC CAPITAL FUND MANAGEMENT
                  </p>
                </div>
              </Link>
            </div>
            {/* card end */}
            {/* card start */}
            <div className="team-card">
              <Link to="/scottjacobs">
                <div className="team-card-img-container">
                  <img src={ScottJImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">
                    SCOTT JACOBS, MBA
                  </h6>
                  <p className="no-margin-bottom">
                    VP OF ACQUISITIONS AND ASSET MANAGEMENT
                  </p>
                  <p className="no-margin-bottom">
                    STRATEGIC CAPITAL FUND MANAGEMENT
                  </p>
                </div>
              </Link>
            </div>
            {/* card end */}
          </div>
          {/* row end */}

          {/* row start */}
          <div className="team-row">
            {/* card start */}
            <div className="team-card">
              <Link to="/brandonhunt">
                <div className="team-card-img-container">
                  <img src={BrandonImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">BRANDON HUNT</h6>
                  <p className="no-margin-bottom">PORTFOLIO MANAGER</p>
                  <p className="no-margin-bottom">
                    STRATEGIC CAPITAL FUND MANAGEMENT
                  </p>
                </div>
              </Link>
            </div>
            {/* card end */}
            {/* card start */}
            <div className="team-card">
              <Link to="/robertbruce">
                <div className="team-card-img-container">
                  <img src={RobImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">ROBERT BRUCE</h6>
                  <p className="no-margin-bottom">CHIEF MARKETING OFFICER</p>
                  <p className="no-margin-bottom">SC DISTRIBUTORS, LLC</p>
                </div>
              </Link>
            </div>
            {/* card end */}
            {/* card start */}
            <div className="team-card">
              <Link to="/jameskearny">
                <div className="team-card-img-container">
                  <img src={JimK} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">
                    JAMES R. KEARNY, MBA
                  </h6>
                  <p className="no-margin-bottom">CHIEF COMPLIANCE OFFICER</p>
                  <p className="no-margin-bottom">SC DISTRIBUTORS, LLC</p>
                </div>
              </Link>
            </div>
            {/* card end */}
          </div>
          {/* row end */}

          {/* row start */}
          <div className="team-row">
            {/* card start */}
            <div className="team-card">
              <Link to="/valeriechapman">
                <div className="team-card-img-container">
                  <img src={ValImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">
                    VALERIE CHAPMAN
                  </h6>
                  <p className="no-margin-bottom">
                    VICE PRESIDENT, HUMAN RESOURCES
                  </p>
                  <p className="no-margin-bottom">SC DISTRIBUTORS, LLC</p>
                </div>
              </Link>
            </div>
            {/* card end */}
            {/* card start */}
            <div className="team-card">
              <Link to="/bryanmarsh">
                <div className="team-card-img-container">
                  <img src={BryanImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">BRYAN MARSH</h6>
                  <p className="no-margin-bottom">CHIEF EXECUTIVE OFFICER</p>
                  <p className="no-margin-bottom">
                    STRATEGIC DATA CENTER FUND MANAGER, LLC
                  </p>
                </div>
              </Link>
            </div>
            {/* card end */}
            {/* card start */}
            <div className="team-card">
              <Link to="/christopherflynn">
                <div className="team-card-img-container">
                  <img src={ChrisImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">
                    CHRISTOPHER FLYNN
                  </h6>
                  <p className="no-margin-bottom">CHIEF INVESTMENT OFFICER</p>
                  <p className="no-margin-bottom">
                    STRATEGIC DATA CENTER FUND MANAGER, LLC
                  </p>
                </div>
              </Link>
            </div>
            {/* card end */}
          </div>
          {/* row end */}

          {/* row start */}
          <div className="team-row">
            {/* card start */}
            <div className="team-card">
              <Link to="/toddrowley">
                <div className="team-card-img-container">
                  <img src={ToddImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">TODD ROWLEY</h6>
                  <p className="no-margin-bottom uppercase">
                    Chief Executive Officer
                  </p>
                  <p className="no-margin-bottom uppercase">
                    Strategic Wireless Infrastructure Fund Advisors, LLC
                  </p>
                </div>
              </Link>
            </div>
            {/* card end */}
            {/* card start */}
            <div className="team-card">
              <Link to="/jscottriggs">
                <div className="team-card-img-container">
                  <img src={scottImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">
                    J. SCOTT RIGGS
                  </h6>
                  <p className="no-margin-bottom uppercase">
                    Chief Operating Officer
                  </p>
                  <p className="no-margin-bottom uppercase">
                    Strategic Wireless Infrastructure Fund Advisors, LLC
                  </p>
                </div>
              </Link>
            </div>
            {/* card end */}
            {/* card start */}
            <div className="team-card">
              <Link to="/ryanwhite">
                <div className="team-card-img-container">
                  <img src={RyanImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">RYAN WHITE</h6>
                  <p className="no-margin-bottom uppercase">
                    Vice President of Business Development
                  </p>
                  <p className="no-margin-bottom uppercase">
                    Strategic Wireless Infrastructure Fund Advisors, LLC
                  </p>
                </div>
              </Link>
            </div>
            {/* card end */}
          </div>
          {/* row end */}

          {/* row start */}
          <div className="team-row">
            {/* card start */}
            <div className="team-card">
              <Link to="/karensiu">
                <div className="team-card-img-container">
                  <img src={KarenImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">KAREN SIU</h6>
                  <p className="no-margin-bottom">FUND CONTROLLER,</p>
                  <p className="no-margin-bottom">
                    STRATEGIC CAPITAL FUND MANAGEMENT
                  </p>
                </div>
              </Link>
            </div>
            {/* card end */}
            {/* card start */}
            <div className="team-card">
              <Link to="/robertmoretta">
                <div className="team-card-img-container">
                  <img src={robertMImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">
                    ROBERT MORETTA
                  </h6>
                  <p className="no-margin-bottom">
                    SENIOR FUND ACCOUNTING MANAGER
                  </p>
                  <p className="no-margin-bottom">
                    STRATEGIC CAPITAL FUND MANAGEMENT
                  </p>
                </div>
              </Link>
            </div>
            {/* card end */}
            {/* card start */}
            <div className="team-card">
              <Link to="/brianeng">
                <div className="team-card-img-container">
                  <img src={BrianEImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">BRIAN ENG</h6>
                  <p className="no-margin-bottom">ASSISTANT CONTROLLER</p>
                  <p className="no-margin-bottom">
                    STRATEGIC CAPITAL FUND MANAGEMENT
                  </p>
                </div>
              </Link>
            </div>
            {/* card end */}
          </div>
          {/* row end */}

          {/* row start */}
          <div className="team-row">
            {/* card start */}
            <div className="team-card">
              <Link to="/christinenguyen">
                <div className="team-card-img-container">
                  <img src={ChristineN} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">
                    CHRISTINE NGUYEN
                  </h6>
                  <p className="no-margin-bottom">SENIOR ACCOUNTANT</p>
                  <p className="no-margin-bottom">SC DISTRIBUTORS, LLC</p>
                </div>
              </Link>
            </div>
            {/* card end */}
            {/* card start */}
            <div className="team-card">
              <Link to="/matthewsimmavong">
                <div className="team-card-img-container">
                  <img src={MattS} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">
                    MATTHEW SIMMAVONG
                  </h6>
                  <p className="no-margin-bottom">SENIOR ACCOUNTANT</p>
                  <p className="no-margin-bottom">SC DISTRIBUTORS, LLC</p>
                </div>
              </Link>
            </div>
            {/* card end */}
            {/* card start */}
            <div className="team-card">
              <Link to="/brandontse">
                <div className="team-card-img-container">
                  <img src={BrandonTImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">BRANDON TSE</h6>
                  <p className="no-margin-bottom">STAFF ACCOUNTANT</p>
                  <p className="no-margin-bottom">SC DISTRIBUTORS, LLC</p>
                </div>
              </Link>
            </div>
            {/* card end */}
          </div>
          {/* row end */}
        </div>
      </div>
    </>
  );
};

export default Leadership;
