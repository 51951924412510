import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import Nav from "./BioNavigationBar";

import bioImage from "../../assets/images/ERIK500X500.jpg";

const ErikBio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Nav />
      <div className="bio-container">
        <div className="bio-breadcrumb">
          <span>{/* <Link to="/#team">Team</Link> / Erik Rostvold */}</span>
        </div>
        <div className="bio-content-wrapper">
          <div className="bio-left-wrapper">
            <Image src={bioImage} />
            <h5>ERIK ROSTVOLD</h5>
            <p>SENIOR VICE PRESIDENT</p>
            <p>STRATEGIC CAPITAL FUND MANAGEMENT</p>
          </div>
          <div className="bio-right-wrapper">
            <p>
              Erik Rostvold is the Senior Vice President of Strategic Capital
              Fund Management and also serves as Senior Vice President of
              Product Management with SC Distributors, LLC.
            </p>
            <p>
              With 15 years of experience, Erik has been involved in over $15B
              in alternative investment offerings. He currently plays integral
              roles in product development, competitive benchmarking, as well as
              the due diligence process with broker dealers and third party due
              diligence firms. Erik is also instrumental in the training of the
              sales team as well as interfacing with financial advisors
              regarding the intricacies of each offering. Prior to SC
              Distributors, Erik was Director of Product Management at Cole Real
              Estate Investments. Prior to Cole, Erik has held product
              management and sales positions with KBS Capital Markets Group,
              MetLife and Pacific Life.
            </p>
            <p>
              Erik lives in Lake Forest, CA, with his wife Patty and daughters,
              Raissa and Thais.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErikBio;
