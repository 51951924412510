import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import Nav from "./BioNavigationBar";

import bioImage from "../../assets/images/SCOTTJ500X500.jpg";

const ScottJBio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Nav />
      <div className="bio-container">
        <div className="bio-breadcrumb">
          <span>{/* <Link to="/#team">Team</Link> / Scott Jacobs */}</span>
        </div>
        <div className="bio-content-wrapper">
          <div className="bio-left-wrapper">
            <Image src={bioImage} />
            <h5>SCOTT JACOBS, MBA</h5>
            <p>VICE PRESIDENT OF ACQUISITIONS AND ASSET MANAGEMENT</p>
            <p>STRATEGIC CAPITAL FUND MANAGEMENT</p>
          </div>
          <div className="bio-right-wrapper">
            <p>
              Scott Jacobs is the Vice President of Acquisitions and Asset
              Management at Strategic Capital Fund Management, and has extensive
              experience in commercial real estate investment and business
              strategy. Scott is instrumental in supporting Strategic Capital
              Fund Management’s data center and wireless infrastructure teams.
              In doing so, he is actively involved in corporate finance
              strategy, underwriting new opportunities, financial modeling,
              asset management, asset and fund-level financing, and
              dispositions.
            </p>
            <p>
              Before joining Strategic Capital Fund Management, Scott led the
              financial analysis team at Northstar Commercial Partners, was a
              key member of the investment team at Amstar, and worked at Credit
              Suisse in the Real Estate Investment Banking group. In his prior
              roles, Scott focused on both domestic and international
              investments and development opportunities across a variety of
              commercial real estate sectors (data centers, office, industrial,
              multifamily, storage, and hospitality). Most recently, he worked
              on the underwriting and analysis of a $350M data center
              development program with a top-5 tech company.
            </p>
            <p>
              Scott earned his M.B.A. from the Yale School of Management,
              concentrating in real estate and finance, where he also received a
              scholarship from the Pension Real Estate Association. For his
              undergraduate studies at Penn State, Scott was a student athlete
              on the lacrosse team and earned a B.S. in Engineering Science with
              a minor in Nanotechnology from the Schreyer Honors College.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScottJBio;
