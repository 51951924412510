import React from "react";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <>
      <div className="about-us_container" id="about-us">
        <div className="about-us_header">
          <span className="about-us_header_text">ABOUT US</span>
        </div>
        <div className="about-us_content_container">
          <div className="about-us_content">
            <p>
              Strategic Capital Fund Management is focused on developing unique
              alternative investment solutions that provide attractive risk
              adjusted returns while protecting investor capital. The company
              strives to create investment opportunities with superior
              management teams, cutting-edge asset classes and distinct product
              structures that can provide durable income, growth potential,
              reduced volatility and low correlation to traditional markets.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
