import React from "react";
import Navigation from "../navigation/Navigation";
import Hero from "../hero/Hero";
import AboutUs from "../aboutUs/AboutUs";
import Invest from "../investmentPhilosophy/InvestPhil";
import Fund from "../fundManagement/FundManagement";
import Leadership from "../leadership/Leadership";
import Contact from "../contactUs/ContactUs";
import Footer from "../footer/Footer";

const Home = () => {
  return (
    <>
      <Navigation />
      <Hero />
      <AboutUs />
      <Invest />
      <Fund />
      <Leadership />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
