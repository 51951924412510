import React from "react";
import { Navbar, Nav, NavItem } from "react-bootstrap";
import { Link } from "react-router-dom";
import { NavHashLink as NavLink } from "react-router-hash-link";

import "./Leadership.css";

import logoImg from "../../assets/images/SCFM-WHITE.png";

const BioNavigationBar = () => {
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <div>
      <Navbar
        collapseOnSelect
        expand="lg"
        fixed="top"
        variant="dark"
        className="di_navbar"
      >
        <Navbar.Brand>
          <Link to="/#home">
            <img src={logoImg} alt="logo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto"></Nav>
          <Nav pullRight>
            <NavItem className="nav-item-spacing">
              <NavLink
                to="/#about-us"
                activeClassName="selected"
                className="nav_link_white nav-link bio-nav-link"
                scroll={(el) => scrollWithOffset(el)}
              >
                About Us
              </NavLink>
            </NavItem>
            <NavItem className="nav-item-spacing">
              <NavLink
                to="/#our-philosophy"
                activeClassName="selected"
                className="nav_link_white nav-link bio-nav-link"
                scroll={(el) => scrollWithOffset(el)}
              >
                Our Philosophy
              </NavLink>
            </NavItem>
            <NavItem className="nav-item-spacing">
              <NavLink
                to="/#team"
                activeClassName="selected"
                className="nav_link_white nav-link bio-nav-link"
                scroll={(el) => scrollWithOffset(el)}
              >
                Team
              </NavLink>
            </NavItem>
            <NavItem className="nav-item-spacing">
              <NavLink
                to="/#fund-management"
                activeClassName="selected"
                className="nav_link_white nav-link bio-nav-link"
                scroll={(el) => scrollWithOffset(el)}
              >
                Fund Management
              </NavLink>
            </NavItem>
            <NavItem className="nav-item-spacing">
              <NavLink
                to="/#contact-us"
                activeClassName="selected"
                className="nav_link_white nav-link bio-nav-link"
                scroll={(el) => scrollWithOffset(el)}
              >
                Contact Us
              </NavLink>
            </NavItem>
            <NavItem className="nav-item-spacing">
              <Link
                to="/digitalinfrastructure"
                className="bio-nav-link nav_link_white nav-link"
              >
                Digital Infrastructure
              </Link>
            </NavItem>
            <NavItem className="nav-item-spacing">
              <a
                href="http://www.scdistributors.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="bio-nav-link nav_link_white nav-link"
              >
                Financial Professionals
              </a>
            </NavItem>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default BioNavigationBar;
