import React from "react";
import swifLogo from "../../assets/images/SW-LOGO.png";
import sdcfLogo from "../../assets/images/SDCF_logo.png";
import "./FundManagement.css";

const FundManagement = (props) => {
  return (
    <>
      <div
        className="fundManagement_container"
        ref={props.refProp}
        id="fund-management"
      >
        <div className="fundManagement_header_container">
          <h1 className="uppercase">fund management</h1>
          <div className="fundManagement_header_content_wrapper">
            <p>
              In developing its investment opportunities, Strategic Capital Fund
              Management strives to assemble management teams that are
              highlighted by deeply experienced and knowledgeable individuals,
              with a history of demonstrated success in their respective asset
              classes and specialties. See below for the current team(s) we have
              assembled.
            </p>
          </div>
        </div>
        <div className="fundManagement_content_container">
          <div className="fundManagement_lower-content_wrapper_swif">
            <div className="swif_logo">
              <img src={swifLogo} alt="swif logo" />
            </div>
            <p>
              The management team at Strategic Wireless Infrastructure Fund
              Advisors, LLC has more than $129 billion in wireless
              infrastructure related transactions and financings and nearly 100+
              years of combined experience sourcing, acquiring, constructing,
              financing and operating telecommunications infrastructure
              networks. The team has deep experience with a variety of
              infrastructure asset types and boasts longstanding industry
              relationships with wireless carriers, independent operators and
              infrastructure developers throughout the United States.
            </p>
            <div className="fundManagement_button-container">
              <span
                className="fundManagement_button"
                onClick={() => window.open("http://swifunds.com/")}
              >
                Learn More
              </span>
            </div>
          </div>
          <div className="fundManagement_lower-content_wrapper">
            <div className="swif_logo">
              <img src={sdcfLogo} alt="swif logo" />
            </div>
            <p>
              The management team at Strategic Data Center Fund Manager, LLC has
              over $32 billion in commercial real estate transactions,
              financings and asset management experience and over 65 years of
              combined experience sourcing, acquiring, constructing and
              financing commercial real estate. The team has a robust history of
              acquisitions, dispositions, development and management of various
              data center types including enterprise, wholesale, colocation,
              edge and switch.
            </p>
            <div className="fundManagement_button-container">
              <span
                className="fundManagement_button"
                onClick={() => window.open("http://www.sdcfunds.com/")}
              >
                Learn More
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FundManagement;
