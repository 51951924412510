import React from "react";
import { Carousel } from "react-bootstrap";

import "./DiMobileStyles.css";

// Image assets
import imgOne from "../../../assets/images/mobile/BLUE-1.png";
import imgTwo from "../../../assets/images/mobile/BLUE-2.png";
import imgThree from "../../../assets/images/mobile/BLUE-3.png";

const SectionOne = () => {
  return (
    <div className="section-container">
      <Carousel indicators className="blue-radial">
        <Carousel.Item className="blue-1-img-bg">
          <img className="d-block w-100" src={imgOne} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={imgTwo} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={imgThree} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <div className="sources-container">
            <p>Sources:</p>
            <p>
              <sup>1</sup> Accenture.com, “Understanding Data in the New”, April 25, 2019.
            </p>
            <p>
              <sup>2</sup> iproperty Management, Smart Home Statistics, 2020.
            </p>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default SectionOne;
