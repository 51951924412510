import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import Nav from "./BioNavigationBar";

import bioImage from "../../assets/images/ChristineNguyen.jpg";

const ChristineNBio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Nav />
      <div className="bio-container">
        <div className="bio-content-wrapper">
          <div className="bio-left-wrapper">
            <Image src={bioImage} />
            <h5>CHRISTINE NGUYEN</h5>
            <p>SENIOR ACCOUNTANT</p>
            <p>SC DISTRIBUTORS, LLC</p>
          </div>
          <div className="bio-right-wrapper">
            <p>
              Christine currently serves as Senior Accountant for the company
              and its affiliates. She has over eight years of accounting
              experience in commercial real estate, alternative investments,
              asset management, REITs, non-profits, and government
              organizations. She has been involved in $18 billion in investment
              offerings throughout her career. She plays an integral role
              preparing financial reports for the company.
            </p>
            <p>
              Christine holds a Bachelor of Science from the Mihaylo College of
              Business and Economics at California State University Fullerton
              (CSUF) with a concentration in Accounting. Her studies included
              taxes, financial accounting, and corporate finance.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChristineNBio;
