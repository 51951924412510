import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import Nav from "./BioNavigationBar";

import bioImage from "../../assets/images/BRANDON500X500.jpg";

const BrandonBio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Nav />
      <div className="bio-container">
        <div className="bio-breadcrumb">
          <span>{/* <Link to="/#team">Team</Link> / Brandon */}</span>
        </div>
        <div className="bio-content-wrapper">
          <div className="bio-left-wrapper">
            <Image src={bioImage} />
            <h5>BRANDON HUNT</h5>
            <p>PORTFOLIO MANAGER</p>
            <p>STRATEGIC CAPITAL FUND MANAGEMENT</p>
          </div>
          <div className="bio-right-wrapper">
            <p>
              Brandon Hunt currently serves as the Portfolio Manager with
              Strategic Capital Fund Management.
            </p>
            <p>
              Brandon has over 12 years of experience in the alternative
              investment industry and has been involved in over $7B in
              investment offerings. He plays integral roles in
              product development, fund management, market research and thought
              leadership for the company. He is also instrumental in product and
              industry training as well as interfacing with institutional firms,
              due diligence personnel and financial advisors regarding the
              intricacies of the company’s investment strategies.
            </p>
            <p>
              Brandon graduated Magna Cum Laude from the University Honors
              Program at California State University Fullerton. His studies
              included real estate and corporate finance, with a special
              interest in alternative investments and derivatives. He was also
              recipient of the CSUF College of Business and Economics Alumni
              Scholarship, CSUF Dean’s Advisory Board Scholarship and the TABR
              Capital Management Scholarship.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandonBio;
