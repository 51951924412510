import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import Nav from "./BioNavigationBar";

import bioImage from "../../assets/images/ScottCorkery_500x500.jpg";

const JimBio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Nav />
      <div className="bio-container">
        <div className="bio-content-wrapper">
          <div className="bio-left-wrapper">
            <Image src={bioImage} />
            <h5>SCOTT W. CORKERY, CPA, CA</h5>
            <p>CHIEF FINANCIAL OFFICER</p>
            <p>STRATEGIC CAPITAL FUND MANAGEMENT</p>
          </div>
          <div className="bio-right-wrapper">
            <p>
              Scott Corkery is an integral part of Strategic Capital Fund
              Management’s executive leadership and is instrumental in
              supporting the company’s digital infrastructure investments teams.
              He plays a critical role in managing the company’s past and
              future financial obligations, providing accurate and timely
              financial reporting, and determining the appropriate mix of debt
              and equity across investment portfolios currently under
              management.
            </p>
            <p>
              Scott’s background includes extensive experience in the hedge
              fund, private equity, and real estate accounting space. Before
              joining Strategic Capital Fund Management, Scott was a Managing
              Director and Head of Private Equity and Real Estate Accounting for
              Sculptor Capital. Prior to Sculptor, Scott was an Assistant
              Controller at York Capital Management. He was also a VP/Assistant
              Manager on the GSAS Toronto team at Goldman Sachs.
            </p>
            <p>
              Scott holds a Bachelor of Science in Biology from Dalhousie
              University and a Bachelor of Commerce from Saint Mary’s
              University. Scott is also a Chartered Accountant and Chartered Professional Accountant with the Institute of Chartered Accountants of
              Nova Scotia, Canada.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default JimBio;
