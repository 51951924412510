import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Home from "./components/home/Home";
import DiHome from "./components/home/diHome";
import JimCondon from "./components/leadership/JimBio";
import PatMiller from "./components/leadership/PatBio";
import KenJaffe from "./components/leadership/KenBio";
import ErikRostvold from "./components/leadership/ErikBio";
import ScottJacobs from "./components/leadership/ScottJBio";
import BrandonHunt from "./components/leadership/BrandonBio";
import RobertBruce from "./components/leadership/RobertBio";
import BryanMarsh from "./components/leadership/BryanBio";
import ChrisFlynn from "./components/leadership/ChrisBio";
import RyanWhite from "./components/leadership/RyanBio";
import ToddRowley from "./components/leadership/ToddBio";
import ScottRiggs from "./components/leadership/ScottRBio";
import KarenSiu from "./components/leadership/KarenBio";
import ScottCorkey from "./components/leadership/ScottCBio";
import ValChapman from "./components/leadership/ValBio";
import JimKBio from "./components/leadership/JimKBio";
import ChristineNBio from "./components/leadership/ChristineNBio";
import MattSBio from "./components/leadership/MattSBio";
import BrandonTBio from "./components/leadership/BrandonTBio";
import BrianBio from "./components/leadership/BrianBio";
import RobertMBio from "./components/leadership/RobertMBio";

import "./App.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Route exact path="/" component={Home} />
        <Route path="/digitalinfrastructure" component={DiHome} />
        <Route path="/jimcondon" component={JimCondon} />
        <Route path="/patmiller" component={PatMiller} />
        <Route path="/kenjaffe" component={KenJaffe} />
        <Route path="/erikrostvold" component={ErikRostvold} />
        <Route path="/scottjacobs" component={ScottJacobs} />
        <Route path="/brandonhunt" component={BrandonHunt} />
        <Route path="/robertbruce" component={RobertBruce} />
        <Route path="/bryanmarsh" component={BryanMarsh} />
        <Route path="/christopherflynn" component={ChrisFlynn} />
        <Route path="/ryanwhite" component={RyanWhite} />
        <Route path="/toddrowley" component={ToddRowley} />
        <Route path="/jscottriggs" component={ScottRiggs} />
        <Route path="/karensiu" component={KarenSiu} />
        <Route path="/scottcorkey" component={ScottCorkey} />
        <Route path="/valeriechapman" component={ValChapman} />
        <Route path="/jameskearny" component={JimKBio} />
        <Route path="/christinenguyen" component={ChristineNBio} />
        <Route path="/matthewsimmavong" component={MattSBio} />
        <Route path="/brandontse" component={BrandonTBio} />
        <Route path="/brianeng" component={BrianBio} />
        <Route path="/robertmoretta" component={RobertMBio} />
      </div>
    </Router>
  );
}

export default App;
