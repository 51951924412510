import React from "react";

import "./DiDisclosure.css";

const DiDisclosure = () => {
  return (
    <div className="di-disclosure-container">
      <p className="no-margin-bottom">Sources:</p>
      <p>
        (1) Accenture.com, “Understanding Data in the New”, April 25, 2019. (2)
        iproperty Management, Smart Home Statistics, 2020. (3) IDC, Data Age
        2025: The Evolution of Data to Life-Critical, April 2017. (4) Cisco
        Visual Networking Index: Forecast & Methodology, 2017-2022. (5) Cisco
        Global Cloud Index 2016-2021. (6) Statista 2020: Number of wireless cell
        sites in the U.S. from 2000-2018. (7) Inside Towers, October 3, 2018:
        Carr: U.S. Needs to Ramp Up to 60,000 Cell Sites a Year. (8) Bank
        Street, Metro Connect USA 2020 presentation. (9) American Tower AMT
        Investor Presentation June 2019: U.S. Technology and 5G Update, AV&Co.
        Research & Analysis, CTIA, UBS forecasts. (10) Business Insider
        Intelligence estimates, 2019; GSMA Intelligence, 2018; Company filings
        2006-2018.(11) Cohen & Steers: Infrastructure REITs Towering Over the 5G
        Economy, July 2019. (12) The Motley Fool January 31, 2018: 9 Facts About
        Artificial Intelligence That Might Surprise You. (13) Digital Realty
        Investor Presentation November 2019, IDC; Statista estimates, December
        2018. (14) vXchnge July 11, 2019: Why Virtual Reality Has Created Data
        Storage Concerns. (15) Auto Tech Review 2020, Flood of Data Will Get
        Generated in Autonomous Cars.
      </p>
      <p className="no-margin-bottom">Disclosure:</p>
      <p>
        Information provided is subject to change. Please note certain
        information in this report constitutes forward-looking statements.
        Actual events, results or performance of the markets covered by this
        report may differ materially from those described. The information
        herein reflects our current views only, is subject to change, and is not
        intended to be promissory or relied upon by the reader. There can be no
        certainty that events will turn out as illustrated herein. Past
        performance is not a reliable indicator of future returns. There is no
        guarantee that any investments will be able to take advantage of
        positive market trends. This is provided for educational and
        illustrative purposes only. Forecasts are not a reliable indicator of
        future returns. Forecasts are based on assumptions, estimates, views and
        hypothetical models or analyses, which might prove inaccurate or
        incorrect.
      </p>
      <p>
        This material was prepared without regard to the specific objectives,
        financial situation or needs of any particular person who may receive
        it. It is intended for informational purposes only. It does not
        constitute investment advice, a recommendation, an offer, solicitation,
        the basis for any contract to purchase or sell any security or other
        instrument, or for Strategic Capital Fund Management or its affiliates
        to enter into or arrange any type of transaction as a consequence of any
        information contained herein. Neither Strategic Capital Fund Management
        nor any of its affiliates gives any warranty as to the accuracy,
        reliability or completeness of information which is contained in this
        document. Except insofar as liability under any statute cannot be
        excluded, no member of Strategic Capital Fund Management, its
        affiliates, employee or associate of them accepts any liability (whether
        arising in contract, in tort or negligence or otherwise) for any error
        or omission in this document or for any resulting loss or damage whether
        direct, indirect, consequential or otherwise suffered by the recipient
        of this document or any other person. The views expressed in this
        document constitute Strategic Capital Fund Management’s judgment at the
        time of issue and are subject to change.
      </p>
      <p>
        All investments are subject to risk, including market fluctuations,
        regulatory change, possible delays in repayment and loss of income and
        principal invested. The value of investments can fall as well as rise
        and you might not get back the amount originally invested at any point
        in time. Investment in digital infrastructure may be or become
        nonperforming after acquisition for a wide variety of reasons.
        Nonperforming digital infrastructure investment may require substantial
        workout negotiations and/or restructuring. Environmental liabilities may
        pose a risk such that the owner or operator of digital infrastructure
        assets may become liable for the costs of removal or remediation of
        certain hazardous substances released on, about, under, or in its
        property. Investments in digital infrastructure are subject to various
        risks, including but not limited to the following:
      </p>
      <ul>
        <li>
          Adverse changes in economic conditions including changes in the
          financial conditions of tenants, buyer and sellers, changes in the
          availability of debt financing, changes in interest rates, real estate
          tax rates and other operating expenses
        </li>
        <li>
          Adverse changes in law and regulation including environmental laws and
          regulations, zoning laws and other governmental rules and fiscal
          policies
        </li>
        <li>
          Environmental claims arising in respect of digital infrastructure
          acquired with undisclosed or unknown environmental problems or as to
          which inadequate reserves have been established
        </li>
        <li>
          Changes in the relative popularity of property types and locations
        </li>
        <li>
          Risks and operating problems arising out of the presence of certain
          construction materials
        </li>
        <li>
          Investment in digital infrastructure may involve a high degree of
          risk, including possible loss of principal amount invested, and may
          only be suitable for sophisticated investors who can bear such losses.
          The value of investments and their derived income may fall or rise.
          Past performance or any prediction, projection or forecast on the
          economy or markets is not indicative of future performance.
        </li>
      </ul>
      <p>
        Information and statistics in this report may have been obtained from
        third parties on their websites. Information is provided solely as a
        convenience and no conclusions should be made based on the data.
        Companies mentioned in these third party articles, unless otherwise
        noted, have no relationship or affiliation with Strategic Capital Fund
        Management. There is no assurance that investors will earn a return
        based upon the statistics, trends and/or opinions presented in these
        third party materials. Strategic Capital Fund Management did not
        contribute to the preparation of the third party material, which was
        independently prepared by an unaffiliated source, and does not claim to
        endorse any specific investment vehicle. Strategic Capital Fund
        Management does not make any representations as to the accuracy,
        timeliness, suitability, completeness, or relevance of any information
        prepared by any unaffiliated third party and takes no responsibility
        therefore. While we believe the data to be reliable, no representation
        is made as to, and no responsibility, warranty or liability is accepted
        for, the accuracy or completeness of such information.
      </p>
    </div>
  );
};

export default DiDisclosure;
