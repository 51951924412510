import React from "react";

import "./DiHero.css";

import heroImg from "../../../assets/images/cables.png";

const DiHero = () => {
  return (
    <div className="di-hero-container">
      <h1>DIGITAL INFRASTRUCTURE</h1>
      <h4>Technology Megatrends Underpin Sector Growth Potential</h4>
      <div className="di-hero-image-container">
        <img src={heroImg} alt="cables" />
      </div>
      <p>
        Over the next decade connected devices, wireless applications and new
        technologies are expected to disrupt nearly every sector of the economy,
        creating colossal amounts of data in their wake. Digital infrastructure
        serves as the backbone of the technology, communications and data
        storage that we rely on every day. Cell towers, data centers, fiber
        networks, and more are critical components that store and deliver the
        information we need to help carry economies into the next digital era.
      </p>
    </div>
  );
};

export default DiHero;
