import React, { useState, useEffect } from "react";

// desktop components
import NavigationBar from "../leadership/BioNavigationBar"; //Bring in bio nav bar - it has the functionality we want.
import HeroContent from "../diComponents/diHero/DiHero";
import BodyContent from "../diComponents/diBody/DiBody";
import Disclosure from "../diComponents/diDisclosure/DiDisclosure";

// mobile components
import MobileContainer from "../diComponents/diMobile/DiMobileContainer";

const DiHome = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Add an event listener for resize, which will update our state
    window.addEventListener("resize", handleWindowSizeChange);

    return function cleanup() {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  });

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const isMobile = width <= 500;

  function renderDisplay() {
    if (isMobile) {
      return (
        <div>
          <MobileContainer />
        </div>
      );
    } else {
      return (
        <div>
          <NavigationBar />
          <HeroContent />
          <BodyContent />
          <Disclosure />
        </div>
      );
    }
  }
  return renderDisplay();
};

export default DiHome;
