import React from "react";
import { Carousel } from "react-bootstrap";

import "./DiMobileStyles.css";

// image assets
import imgOne from "../../../assets/images/mobile/GREEN-1.png";
import imgTwo from "../../../assets/images/mobile/GREEN-2.png";
import imgThree from "../../../assets/images/mobile/GREEN-3.png";

const SectionTwo = () => {
  return (
    <div className="section-container">
      <Carousel indicators className="green-radial">
        <Carousel.Item className="green-1-img-bg">
          <img className="d-block w-100" src={imgOne} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item className="green-2-img-bg">
          <img className="d-block w-100" src={imgTwo} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={imgThree} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <div className="sources-container">
            <p>Sources:</p>
            <p>
              <sup>3</sup> IDC, Data Age 2025: The Evolution of Data to
              Life-Critical, April 2017.
            </p>
            <p>
              <sup>4</sup> Cisco Visual Networking Index: Forecast &
              Methodology, 2017-2022
            </p>
            <p>
              <sup>5</sup> Cisco Global Cloud Index 2016-2021.
            </p>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default SectionTwo;
