import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import Nav from "./BioNavigationBar";

import bioImage from "../../assets/images/RobertMoretta.jpg";

const RobertMBio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Nav />
      <div className="bio-container">
        <div className="bio-content-wrapper">
          <div className="bio-left-wrapper">
            <Image src={bioImage} />
            <h5>ROBERT MORETTA</h5>
            <p>SENIOR FUND ACCOUNTING MANAGER</p>
            <p>STRATEGIC DATA CENTER FUND MANAGER, LLC</p>
          </div>
          <div className="bio-right-wrapper">
            <p>
              Robert Moretta currently serves as Senior Fund Accounting Manager
              for Strategic Capital Fund Management. He has extensive experience
              in the private equity and hedge fund accounting space. Before
              joining the company, Robert was a Fund Accounting Manager at
              Sculptor Capital, and prior to that role he served as Assurance
              Supervisor at RSM US LLP. He also previously worked as an Audit
              Associate at Raich Ende Malter & Co. LLP.
            </p>
            <p>
              Robert holds a Bachelor of Arts in Economics from the University
              of Albany and a Master of Business Administration in Accounting
              from St. Joseph’s College. He is also a Certified Public
              Accountant.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RobertMBio;
