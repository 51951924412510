import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import Nav from "./BioNavigationBar";

import bioImage from "../../assets/images/CHRIS500X500.jpg";

const ChrisBio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Nav />
      <div className="bio-container">
        <div className="bio-breadcrumb">
          <span>{/* <Link to="/#team">Team</Link> / Chris */}</span>
        </div>
        <div className="bio-content-wrapper">
          <div className="bio-left-wrapper">
            <Image src={bioImage} />
            <h5>CHRISTOPHER J. FLYNN</h5>
            <p>CHIEF INVESTMENT OFFICER</p>
            <p>STRATEGIC DATA CENTER FUND MANAGER, LLC</p>
          </div>
          <div className="bio-right-wrapper">
            <p>
              Chris Flynn brings more than 25 years of commercial real estate
              experience and well over $25 billion in overall real estate
              transaction experience to Strategic Data Center Fund Manager, LLC.
              As Chief Investment Officer, Chris provides the strategic vision
              to turn business goals into actionable plans with appropriate risk
              management. In this role, he is responsible for acquisitions,
              leasing and management strategy.
            </p>
            <p>
              Prior to Strategic Data Center Fund Manager, LLC, Chris was
              President at Lightstone Data Fund where he served as the company’s
              leader in the investment of digital infrastructure assets. In this
              capacity, he had overall responsibility for the strategic
              planning, capital raising, financing, acquisitions, development,
              operation and leasing.
            </p>
            <p>
              Prior to joining Lightstone, Chris was a Vice President of Global
              Real Estate at EdgeConneX, a wholesale data center owner and
              developer, where he was responsible for all aspects of real estate
              including the acquisition and development of 40 data centers in
              the U.S., Canada, Europe and South America. The EdgeConneX
              portfolio consisted of over 3 million square feet, in excess of
              350 megawatts of critical power and over $6 billion worth of data
              center transactions which helped EdgeConneX to grow from one data
              center in 2013 to a global presence in the edge and hyperscale
              market.
            </p>
            <p>
              He was formerly the Director of Real Estate at XO Communications,
              a telecommunications company owned by Verizon Communications which
              provides managed and converged internet protocol network services
              for small and medium-sized enterprises. During his time at XO,
              Chris led a real estate team responsible for over $10 billion in
              acquisitions, renewals and dispositions and managed over 50,000
              telecom license agreements. Chris serves on the board of directors
              for The First Tee of Washington, D.C. and volunteers at Stone
              Ridge School and Mater Dei School.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChrisBio;
