import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import Nav from "./BioNavigationBar";

import bioImage from "../../assets/images/SCOTTR500X500.jpg";

const ScottRBio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Nav />
      <div className="bio-container">
        <div className="bio-breadcrumb">
          <span>{/* <Link to="/#team">Team</Link> / Jim Condon */}</span>
        </div>
        <div className="bio-content-wrapper">
          <div className="bio-left-wrapper">
            <Image src={bioImage} />
            <h5>J. SCOTT RIGGS</h5>
            <p className="uppercase">Chief Operating Officer</p>
            <p className="uppercase"> Strategic Wireless Infrastructure Fund Advisors, LLC</p>
          </div>
          <div className="bio-right-wrapper">
            <p>
              J. Scott Riggs is an experienced telecommunications executive with
              over twenty-five years of operating knowledge associated with
              wireless and fiber networks. Mr. Riggs serves as the Chief
              Operating Officer of the advisor and is responsible for procuring
              investment and operating fiber infrastructure on behalf of the
              fund. Mr. Riggs also serves as a member of the investment
              committee of the advisor.
            </p>
            <p>
              Throughout his career, he has been directly involved in over $4
              billion of telecommunications acquisitions, dispositions, and
              financings and has been primarily responsible for all aspects of
              infrastructure and operations applicable to the deployment and
              ongoing management of numerous cellular, PCS, wireless broadband,
              and fiber networks. Previously Mr. Riggs Co-founded and served as
              Chief Executive Officer and Chairman of Clearwave Communications,
              a regional fiber-optic company located in southern Illinois where
              he grew the company to become one of the most successful
              regionally operated fiber optic start-up companies in the U.S.
              with an estimated market value in excess of $300 million.
            </p>
            <p>
              He has also served on the Board of Directors and/or Board of
              Managers of various companies. Prior to Clearwave, Mr. Riggs
              served as Regional Vice President of Tritel, Inc. (d/b/a SunCom)
              where he was instrumental in the successful oversight and
              development of the integrated network infrastructure and market
              launches which was deemed one of the most successful wireless
              affiliate transactions in telecommunications history. Mr. Riggs
              holds a Bachelor of Science Degree from Ohio University in Athens,
              Ohio.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScottRBio;
