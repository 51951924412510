import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import Nav from "./BioNavigationBar";

import bioImage from "../../assets/images/JIM500X500.jpg";

const JimBio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Nav />
      <div className="bio-container">
        <div className="bio-content-wrapper">
          <div className="bio-left-wrapper">
            <Image src={bioImage} />
            <h5>JIM CONDON, MBA, CIMA®</h5>
            <p>MANAGING PARTNER, STRATEGIC CAPITAL MANAGEMENT HOLDINGS</p>
            <p>PRESIDENT, STRATEGIC CAPITAL FUND MANAGEMENT</p>
          </div>
          <div className="bio-right-wrapper">
            <p>
              Jim Condon joined Strategic Capital in 2010 and currently serves
              as Managing Partner of Strategic Capital Management Holdings,
              President of Strategic Capital Fund Management LLC, President of
              Strategic Datasphere, LLC, and President of MainStreet Digital
              Broadband, LLC. Mr. Condon also serves as Chief Operating Officer
              of SC Distributors, LLC, and is a Director and board member of
              Pegasus Digital Mobility Acquisition Company. In his role as
              President of various entities, Mr. Condon oversees the operations,
              investment, capital markets, and asset management activities for
              each company. Mr. Condon is also a member of the investment
              committees and board of directors for each of Strategic Capital’s
              sponsored private and public investment programs, including
              Strategic Wireless Infrastructure Fund I, Strategic Data Center
              Fund, Strategic Datasphere, MainStreet Digital Broadband, as well
              as Chairman of the Board for Strategic Wireless Infrastructure
              Fund II, LLC.
            </p>
            <p>
              Mr. Condon has over 18 years of experience in investment
              management within the financial services industry. In his previous
              position at the Capital Group, American Funds, he was responsible
              for managing the internal distribution teams for each of the
              American Funds products. Prior to American Funds, Mr. Condon was
              Vice-President at Schroder Investment Management, a global
              investment management firm with over $785 billion of assets, based
              in New York City, where he was chartered with building the
              internal distribution team from the ground up. He was instrumental
              in helping the firm raise over $4 billion in revenue in various
              separately managed accounts and alternative investments. Mr.
              Condon also worked for Refco Alternative Investments, the hedge
              fund division of Refco, where he held an investment analyst
              position. While at Refco, he was responsible for helping
              raise/invest over $2.4 billion in assets over a
              two-and-a-half-year period. Throughout his career, Mr. Condon has
              worked with REITs, SPAC’s, infrastructure, managed futures, hedge
              funds, renewable energy, social impact investing, equity and fixed
              income vehicles and private placements.
            </p>
            <p>
              Mr. Condon holds an MBA., cum laude, from the University of Notre
              Dame, a BS in finance from Park School of Business at Ithaca
              College, and a CIMA from the Wharton School at The University of
              Pennsylvania.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default JimBio;
