import React from "react";
import { Carousel } from "react-bootstrap";

import "./DiMobileStyles.css";

// Image assets
import imgOne from "../../../assets/images/mobile/YELLOW-1.png";
import imgFour from "../../../assets/images/mobile/YELLOW-4.png";

const SectionFour = () => {
  return (
    <div className="section-container">
      <Carousel indicators className="yellow-radial">
        <Carousel.Item className="yellow-1-img-bg">
          <img className="d-block w-100" src={imgOne} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item className="yellow-2-img-bg"></Carousel.Item>
        <Carousel.Item className="yellow-3-img-bg"></Carousel.Item>
        <Carousel.Item className="yellow-4-img-bg">
          <img className="d-block w-100" src={imgFour} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <div className="sources-container">
            <p>Sources:</p>
            <p>
              <sup>6</sup> Statista 2020: Number of wireless cell sites in the
              U.S. from 2000-2018.
            </p>
            <p>
              <sup>7</sup> Inside Towers, October 3, 2018: Carr: U.S. Needs to
              Ramp Up to 60,000 Cell Sites a Year.
            </p>
            <p>
              <sup>8</sup> Bank Street, Metro Connect USA 2020 presentation.
            </p>
            <p>
              <sup>9</sup> American Tower AMT Investor Presentation June 2019:
              U.S. Technology and 5G Update, AV&Co. Research & Analysis, CTIA,
              UBS forecasts.
            </p>
            <p>
              <sup>10</sup> Business Insider Intelligence estimates, 2019; GSMA
              Intelligence, 2018; Company filings 2006-2018.
            </p>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default SectionFour;
