import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import Nav from "./BioNavigationBar";

import bioImage from "../../assets/images/Val.jpg";

const ValBio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Nav />
      <div className="bio-container">
        <div className="bio-content-wrapper">
          <div className="bio-left-wrapper">
            <Image src={bioImage} />
            <h5>VALERIE CHAPMAN</h5>
            <p>VICE PRESIDENT, HUMAN RESOURCES</p>
            <p>SC DISTRIBUTORS, LLC</p>
          </div>
          <div className="bio-right-wrapper">
            <p>
              Valerie Chapman serves as VP, Human Resources, bringing over 17
              years of diverse, strategic Human Resources experience to the
              team. Her areas of subject matter expertise include payroll, HR
              compliance with all applicable federal, state, and local laws,
              performance management, safety, policy and handbook development,
              compensation planning, and benefits administration. Valerie began
              her career as a Human Resources Coordinator where she was quickly
              promoted into the role of Employee Relations and on to the
              Management Team. Valerie is an active member of many prominent HR
              organizations including the Society for Human Resource Management.
              Valerie lives in Huntington Beach with her husband, 2 sons and
              dog.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ValBio;
