import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import Nav from "./BioNavigationBar";

import bioImage from "../../assets/images/BRYAN500x500.jpg";

const BryanBio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Nav />
      <div className="bio-container">
        <div className="bio-breadcrumb">
          <span>{/* <Link to="/#team">Team</Link> / Bryan */}</span>
        </div>
        <div className="bio-content-wrapper">
          <div className="bio-left-wrapper">
            <Image src={bioImage} />
            <h5>BRYAN MARSH</h5>
            <p>CHIEF EXECUTIVE OFFICER</p>
            <p>STRATEGIC DATA CENTER FUND MANAGER, LLC</p>
          </div>
          <div className="bio-right-wrapper">
            <p>
              Bryan Marsh is the Chief Executive Officer of Strategic Data
              Center Fund Manager, LLC and is responsible for sourcing,
              analyzing, structuring, financing and executing acquisition,
              expansion and leasing activities on behalf of the company. With
              his nearly 40 years of real estate experience, he has been
              directly involved in approximately $7 billion in commercial real
              estate acquisitions, dispositions, development, financings and
              asset management.
            </p>
            <p>
              Prior to Strategic Data Center Fund Manager, Bryan was a Vice
              President at Digital Realty where he served as the company’s
              Portfolio Manager for its U.S. Central region. In this capacity,
              he had overall responsibility for the strategic planning,
              acquisitions, dispositions, development, operation, leasing and
              financial performance of a portfolio of data centers, internet
              gateways and powered base buildings valued in excess of $5
              billion. His portfolio ranged in size from 40 to 55 buildings and
              from 5.8 million to 8.1 million square feet with annual operating
              revenues ranging from $400 million to $600 million.
            </p>
            <p>
              Prior to joining Digital Realty, Bryan was a Senior Vice President
              and Partner at Equastone, a private equity firm, where he was
              involved in the acquisition and asset management of a 4.3 million
              square foot office portfolio in Dallas valued at over $600
              million. At that time, Equastone was the city’s 3rd largest office
              landlord with 21 buildings spread across most of the city’s major
              submarkets.
            </p>
            <p>
              He also was formerly a Vice President with RREEF, a pension fund
              advisor and investment management firm, where he led an office
              that managed over 18 million square feet of office and industrial
              space in Texas and Oklahoma. Bryan personally oversaw a roughly 9
              million square foot portfolio valued at approximately $500
              million. Bryan has closed real estate transactions in over 250
              cities and 15 states during his career. He is a past Chairman of
              the Board of Directors of the Richardson Chamber of Commerce, and
              he also served on the Board of Directors of Tech Titans which is
              the largest technology trade association in North Texas. He has
              been recognized for each of the past 5 years as a member of the
              Dallas 500 which is a list of the most powerful and influential
              business leaders in the DFW metro area according to D CEO
              Magazine. He graduated from the University of North Texas with a
              Bachelor of Business Administration degree with a double major in
              finance and real estate.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BryanBio;
