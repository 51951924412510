import React from "react";

import "./InvestPhil.css";

import philImage from "../../assets/images/SLIDE3.jpg";

const InvestPhil = (props) => {
  return (
    <>
      <div
        className="investPhil_container"
        ref={props.refProp}
        id="our-philosophy"
      >
        <div className="investPhil_image_container">
          <img
            src={philImage}
            alt="invest phil hero"
            className="investPhil_hero_img"
          />
        </div>
        <div className="investPhil_content_wrapper">
          <div className="investPhil_leftside_content">
            <h1>OUR</h1>
            <h1>INVESTMENT</h1>
            <h1>PHILOSOPHY</h1>
          </div>
          <div className="investPhil_rightside_content">
            <p>
              Today’s dynamic financial markets present significant challenges
              for both financial advisors and investors. However, in the face of
              so much uncertainty, advisors’ clients still expect them to
              deliver competitive returns - all while appropriately managing
              risk. We believe that alternative investments play a critical role
              in overall portfolio construction as a powerful diversifier, as
              well as providing exposure to non-correlated assets, unique
              strategies and desirable management teams.
            </p>
            <p>
              Old philosophies, such as the 60/40 portfolio model, no longer
              seem to fit the realities of today’s market. We believe that the
              use of alternative investments can augment client portfolios, help
              reduce overall risk and provide added return potential. We feel
              this is especially true in markets or asset classes that display
              strong underlying fundamentals and key market drivers that point
              to favorable overall growth trends. Exposure to inefficient
              markets and asset classes also provides considerable
              opportunities. Transactions are often conducted off-market,
              through longstanding relationships and managers often gain
              intimate knowledge of opportunistic situations where there may be
              limited competition. This is where we can leverage experienced
              management teams, exploit market inefficiencies and provide
              significant value to investors.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestPhil;
