import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import Nav from "./BioNavigationBar";

import bioImage from "../../assets/images/BrandonTse.jpg";

const BrandonTBio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Nav />
      <div className="bio-container">
        <div className="bio-content-wrapper">
          <div className="bio-left-wrapper">
            <Image src={bioImage} />
            <h5>BRANDON TSE</h5>
            <p>STAFF ACCOUNTANT</p>
            <p>SC DISTRIBUTORS, LLC</p>
          </div>
          <div className="bio-right-wrapper">
            <p>
              Brandon Tse is a Staff Accountant tasked with accounts payable for
              the company and its affiliates. He’s a college graduate and has
              internship experience in insurance, municipal finance, and
              operations.
            </p>
            <p>
              Brandon holds a Bachelor of Science from the Gabelli School of
              Business at Fordham University with his studies focused on
              finance. His coursework included financial modeling, portfolio
              management, and global financial statement analysis. While
              achieving his degree, Brandon also obtained a secondary
              concentration in value investing.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandonTBio;
