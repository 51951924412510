import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { NavHashLink as NavLink } from "react-router-hash-link";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavItem from "react-bootstrap/NavItem";
import LogoWhite from "../../assets/images/SCFM-WHITE.png";
import Image from "react-bootstrap/Image";

import "./Navigation.css";

const Navigation = () => {
  const [transform, setTransform] = useState(false);

  useEffect(() => {
    // initiate the event handler
    window.addEventListener("scroll", handleScroll, true);

    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 150) {
      setTransform(true);
    } else {
      setTransform(false);
    }
  };

  const handleHomePress = () => {
    window.scrollTo(0, 0);
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        fixed="top"
        variant="dark"
        className={transform === true ? "nav_color" : "nav_clear"}
      >
        <Navbar.Brand onClick={() => handleHomePress()}>
          <Image src={LogoWhite} className="nav-img" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto"></Nav>
          <Nav>
            <NavLink
              to="/#about-us"
              activeClassName="selected"
              className="nav_link_white nav-link"
              scroll={(el) => scrollWithOffset(el)}
            >
              About Us
            </NavLink>
            <NavLink
              to="/#our-philosophy"
              activeClassName="selected"
              className="nav_link_white nav-link"
              scroll={(el) => scrollWithOffset(el)}
            >
              Our Philosophy
            </NavLink>
            <NavLink
              to="/#team"
              activeClassName="selected"
              className="nav_link_white nav-link"
              scroll={(el) => scrollWithOffset(el)}
            >
              Team
            </NavLink>
            <NavLink
              to="/#fund-management"
              activeClassName="selected"
              className="nav_link_white nav-link"
              scroll={(el) => scrollWithOffset(el)}
            >
              Fund Management
            </NavLink>
            <NavLink
              to="/#contact-us"
              activeClassName="selected"
              className="nav_link_white nav-link"
              scroll={(el) => scrollWithOffset(el)}
            >
              Contact Us
            </NavLink>
            <NavItem>
              <Link
                to="/digitalinfrastructure"
                className="nav_link_white nav-link"
              >
                Digital Infrastructure
              </Link>
            </NavItem>
            <NavItem>
              <a
                href="http://www.scdistributors.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="nav_link_white nav-link"
              >
                Financial Professionals
              </a>
            </NavItem>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Navigation;
