import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import Nav from "./BioNavigationBar";

import bioImage from "../../assets/images/ROB500X500.jpg";

const RobertBio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Nav />
      <div className="bio-container">
        <div className="bio-breadcrumb">
          <span>{/* <Link to="/#team">Team</Link> / Jim Condon */}</span>
        </div>
        <div className="bio-content-wrapper">
          <div className="bio-left-wrapper">
            <Image src={bioImage} />
            <h5>ROBERT BRUCE</h5>
            <p>CHIEF MARKETING OFFICER</p>
            <p>SC DISTRIBUTORS, LLC</p>
          </div>
          <div className="bio-right-wrapper">
            <p>
              Rob Bruce serves as Chief Marketing Officer for SC Distributors
              and has over 25 years of diverse financial services sales and
              marketing experience with emphasis on alternative investments and
              start-up ventures. In his role, he is responsible for planning,
              developing, implementing and monitoring the company’s overall
              marketing strategy.  In doing so, he ensures that the
              organization's message and value proposition is distributed across
              all channels, targeted audiences and affiliated companies in order
              to meet corporate objectives.
            </p>
            <p>
              Prior to SC Distributors, Mr. Bruce served as Vice President of
              Sales & Marketing for a California-based real estate company where
              he was chartered with building a marketing department to support
              the branding and capital raising efforts for two real estate funds
              as well as two private accredited investor offerings. In his
              previous position as Director of Marketing at United Healthcare,
              he spearheaded the construction of a marketing department to
              support the broker distribution of the company’s Medicare products
              – a new venture for the firm at the time. He was instrumental in
              helping build and support this new distribution channel for United
              Healthcare that quickly grew to represent 75% of the division’s
              total sales. Prior to United Healthcare, Mr. Bruce was at MetLife
              Investors where he served as Vice President of Marketing and was
              responsible for marketing the company's variable and fixed
              annuities, life insurance and long-term care insurance products.
              Mr. Bruce helped to launch this MetLife subsidiary in April of
              2001 and helped build and structure a marketing department that
              supported one of the financial industry’s leading insurance
              distribution organizations. Previous to MetLife Investors, Mr.
              Bruce led marketing and sales programs at AXA, UBS, Merrill Lynch
              and Bloomberg. His varied product background includes experience
              with REITs, private placements, annuities, life insurance,
              long-term care insurance, other alternative investments and
              Medicare products.
            </p>
            <p>
              Mr. Bruce is a native of New Jersey where he received his B.S.
              degree in Business from Kean University. He holds his FINRA Series
              7, 63 and 24 registrations and currently resides in California
              with his wife and three daughters.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RobertBio;
