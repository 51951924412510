import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import Nav from "./BioNavigationBar";

import bioImage from "../../assets/images/PAT500X500.jpg";

const PatBio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Nav />
      <div className="bio-container">
        <div className="bio-breadcrumb">
          <span>{/* <Link to="/#team">Team</Link> / Pat Miller */}</span>
        </div>
        <div className="bio-content-wrapper">
          <div className="bio-left-wrapper">
            <Image src={bioImage} />
            <h5>PAT MILLER</h5>
            <p>MANAGING PARTNER, STRATEGIC CAPITAL MANAGEMENT HOLDINGS</p>
            <p>PRESIDENT, SC DISTRIBUTORS, LLC</p>
          </div>
          <div className="bio-right-wrapper">
            <p>
              Patrick J. Miller is a Co-Founder and Managing Partner of
              Strategic Capital Management Holdings, a multi-product alternative
              investment distribution and advisory business, and serves as
              President of SC Distributors, LLC, Stratcap Holdings' managing
              broker/dealer. He is responsible for overall strategy, new
              business development and product marketing. In addition, Mr.
              Miller has served on the Board of Trustees for the Investment
              Program Association, the leading non-profit trade association for
              the direct investments industry.
            </p>
            <p>
              Prior to co-founding Stratcap Holdings, Mr. Miller served as
              President of KBS Capital Markets Group, LLC from the company’s
              inception in October 2005. During Mr. Miller’s tenure, KBS Capital
              Markets Group raised approximately $2.3 billion in investment
              capital for KBS Real Estate Investment Trust, Inc. and KBS Real
              Estate Investment Trust II, Inc. Prior to joining KBS Capital
              Markets Group, Mr. Miller served as President and Chief Executive
              Officer of Financial Campus, an online education and training
              company serving the financial services industry. Mr. Miller was
              responsible for facilitating the successful acquisition of
              Financial Campus by the Thomson Corporation in 2004. Previously,
              Mr. Miller served as Chief Executive Officer of Equitable
              Distributors Inc., the wholesale distribution subsidiary of AXA
              Financial, Inc. Mr. Miller was elected Chief Executive Officer of
              Equitable Distributors in 2000 after having joined Equitable
              Distributors at its inception in 1996 as President of the
              Wirehouse Division.
            </p>
            <p>
              Mr. Miller began his career as a Wholesaler with American Funds
              Distributors. Mr. Miller received a Bachelor of Arts from the
              University of California at Los Angeles.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatBio;
