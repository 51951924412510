import React from "react";
import { Carousel } from "react-bootstrap";

import "./DiMobileStyles.css";

// Image assets
import imgOne from "../../../assets/images/mobile/RED-1.png";
import imgTwo from "../../../assets/images/mobile/RED-2.png";
import imgThree from "../../../assets/images/mobile/RED-3.png";
import imgFour from "../../../assets/images/mobile/RED-4.png";
import imgFive from "../../../assets/images/mobile/RED-5.png";

const SectionThree = () => {
  return (
    <div className="section-container">
      <Carousel indicators className="red-radial">
        <Carousel.Item className="red-1-img-bg">
          <img className="d-block w-100" src={imgOne} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item className="red-2-img-bg">
          <img className="d-block w-100" src={imgTwo} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={imgThree} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={imgFour} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={imgFive} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <div className="sources-container">
            <p>Sources:</p>
            <p>
              <sup>11</sup> Cohen & Steers: Infrastructure REITs Towering Over
              the 5G Economy, July 2019.
            </p>
            <p>
              <sup>12</sup> The Motley Fool January 31, 2018: 9 Facts About
              Artificial Intelligence That Might Surprise You.
            </p>
            <p>
              <sup>13</sup> Digital Realty Investor Presentation November 2019,
              IDC; Statista estimates, December 2018.
            </p>
            <p>
              <sup>14</sup> vXchnge July 11, 2019: Why Virtual Reality Has
              Created Data Storage Concerns.
            </p>
            <p>
              <sup>15</sup> Auto Tech Review 2020, Flood of Data Will Get
              Generated in Autonomous Cars.
            </p>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default SectionThree;
