import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import Nav from "./BioNavigationBar";

import bioImage from "../../assets/images/karen-siu.jpg";

const KarenBio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Nav />
      <div className="bio-container">
        <div className="bio-content-wrapper">
          <div className="bio-left-wrapper">
            <Image src={bioImage} />
            <h5>KAREN SIU</h5>
            <p>FUND CONTROLLER</p>
            <p>STRATEGIC CAPITAL FUND MANAGEMENT</p>
          </div>
          <div className="bio-right-wrapper">
            <p>
              Karen Siu currently serves as the Fund Controller for Strategic
              Capital Fund Management. She has extensive experience in the hedge
              fund, private equity, and the real estate accounting space. Before
              joining the company, she was the Controller of real estate
              accounting at Sculptor Capital, and prior to that role she served
              as Manager at Goldman Sachs Asset Management. She also previously
              worked as a Senior Accountant at York Capital Management and
              PricewaterhouseCoopers.
            </p>
            <p>
              Karen holds a Bachelor of Science in Accounting and Information
              Studies from Syracuse University and is a Certified Public
              Accountant.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default KarenBio;
