import React, { useEffect } from "react";

import "./DiBody.css";

import firstImg from "../../../assets/images/PG1.jpg";
import secondImg from "../../../assets/images/PG2.jpg";
import thirdImg from "../../../assets/images/PG3.jpg";
import fourthImg from "../../../assets/images/PG4.jpg";

const DiBody = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <div className="di-body-container">
        <div>
          <img src={firstImg} alt="first" />
        </div>
        <div>
          <img src={secondImg} alt="second" />
        </div>
        <div>
          <img src={thirdImg} alt="third" />
        </div>
        <div>
          <img src={fourthImg} alt="fourth" />
        </div>
      </div>
    </>
  );
};

export default DiBody;
