import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import Nav from "./BioNavigationBar";

import bioImage from "../../assets/images/MattSimmavong.jpg";

const MattSBio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Nav />
      <div className="bio-container">
        <div className="bio-content-wrapper">
          <div className="bio-left-wrapper">
            <Image src={bioImage} />
            <h5>MATTHEW SIMMAVONG</h5>
            <p>SENIOR ACCOUNTANT</p>
            <p>SC DISTRIBUTORS, LLC</p>
          </div>
          <div className="bio-right-wrapper">
            <p>
              Matthew is a Senior Accountant with over four years of accounting
              experience primarily in alternative investment asset management
              and non-profit organizations. Matthew currently serves as a Senior
              Accountant and is responsible for preparing the financial
              reporting for the company and its affiliates.
            </p>
            <p>
              Matthew holds a Bachelor of Science from the Mihaylo College of
              Business and Economics at California State University Fullerton
              (CSUF) with a concentration in Accounting. His studies included
              auditing, financial accounting, cost accounting, and corporate
              finance.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MattSBio;
