import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import Nav from "./BioNavigationBar";

import bioImage from "../../assets/images/BrianEng.jpg";

const BrianBio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Nav />
      <div className="bio-container">
        <div className="bio-content-wrapper">
          <div className="bio-left-wrapper">
            <Image src={bioImage} />
            <h5>BRIAN ENG</h5>
            <p>ASSISTANT CONTROLLER</p>
            <p>STRATEGIC CAPITAL FUND MANAGEMENT</p>
          </div>
          <div className="bio-right-wrapper">
            <p>
              Brian Eng currently serves as an Assistant Controller for
              Strategic Capital Fund Management. He has extensive experience in
              the private equity and real estate fund accounting space. Before
              joining Strategic Capital Fund Management, Brian was a Fund
              Accounting Manager at Sculptor Capital. He also previously worked
              as a Senior Assurance Associate at RSM US.
            </p>
            <p>
              Brian holds a Bachelor of Science and Master of Science in
              Accounting from Binghamton University.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrianBio;
