import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import Nav from "./BioNavigationBar";

import bioImage from "../../assets/images/JIMKEARNY.jpg";

const JimKBio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Nav />
      <div className="bio-container">
        <div className="bio-content-wrapper">
          <div className="bio-left-wrapper">
            <Image src={bioImage} />
            <h5>JAMES R. KEARNY, MBA</h5>
            <p>CHIEF COMPLIANCE OFFICER</p>
            <p>SC DISTRIBUTORS, LLC</p>
          </div>
          <div className="bio-right-wrapper">
            <p>
              Jim has been with SC Distributors, LLC since October of 2010 and
              is its Chief Compliance Officer. Jim has more than 20 years of
              progressively responsible experience in SEC/FINRA regulatory
              compliance, audits, product distribution and due diligence, and
              asset manager research. He has worked in multiple senior operating
              roles at a number of dual registered BDs/Investment Advisors in
              New York City and on the West Coast such as Lehman Brothers,
              Donaldson Lufkin, & Jenrette, ING, Canterbury Consulting, and
              Dunham and Associates Investment Counsel.
            </p>
            <p>
              Mr. Kearny has an B.S. Marketing and an M.B.A. in Management from
              St. John’s University in Queens, New York. He has a Series 7, 14,
              24, 53, 55, 57, 63, and 99 registrations. He currently lives in
              Southern California with his wife and children.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default JimKBio;
