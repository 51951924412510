import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import Nav from "./BioNavigationBar";

import bioImage from "../../assets/images/KEN500X500.jpg";

const KenBio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Nav />
      <div className="bio-container">
        <div className="bio-breadcrumb">
          <span>{/* <Link to="/#team">Team</Link> / Ken Jaffe */}</span>
        </div>
        <div className="bio-content-wrapper">
          <div className="bio-left-wrapper">
            <Image src={bioImage} />
            <h5>KEN JAFFE</h5>
            <p>MANAGING PARTNER, STRATEGIC CAPITAL MANAGEMENT HOLDINGS</p>
            <p>PRESIDENT, STRATEGIC CAPITAL ADVISORY SERVICES</p>
          </div>
          <div className="bio-right-wrapper">
            <p>
              Ken Jaffe is a Co-Founder and Managing Partner of Strategic
              Capital Management Holdings and serves as President of Strategic
              Capital Advisory Services, LLC since the company’s formation in
              September 2009. Mr. Jaffe is responsible for developing and
              maintaining a proprietary technology and operations support
              platform for the companies offering investment products through
              the SC Distributors’ network. Prior to co-founding Strategic
              Capital, Mr. Jaffe cofounded and served as the Chief Operating
              Officer of KBS Capital Markets Group, LLC from the Company’s
              inception in 2005. Prior to joining KBS, Mr. Jaffe founded and
              served as President of Jaffe Technologies, a technology consulting
              firm servicing large financial institutions such as the John
              Hancock Financial Network. Prior to JaffeTech, Mr. Jaffe served as
              Executive Vice President and Chief Information Officer of MetLife
              Investors.
            </p>
            <p>
              He was responsible for consolidating the technology platforms of
              two existing insurance companies owned by MetLife while
              establishing a state of the art web-based infrastructure for the
              newly merged entity. This platform included eCommerce initiatives
              for distribution of financial services products, process
              re-engineering for telemarketing and customer service functions
              and the development of a CRM / Data Warehousing solution. While at
              MetLife Mr. Jaffe was invited to join several industry advisory
              boards including Palm Computing, Filenet, Docucorp and the NAVA
              Technology Steering Committee. He was also the recipient of
              Insurance and Technology Magazine’s Elite 8 award for 2002. Prior
              to Metlife, Mr. Jaffe was at Equitable Distributors, Inc. where he
              served as its Chief Information Officer. At this time, Mr. Jaffe
              was appointed one of Computer World’s top 100 IT Professionals in
              America and received a ranking of #27 in Information Week’s top
              500 technology innovators for 2000. Prior to Equitable, Mr. Jaffe
              served at PIMCO Advisors and was responsible for all Management
              Information Systems in their managed assets division.
            </p>
            <p>
              He began his financial services career in 1987 with J.P. Morgan as
              a Programmer/Analyst focusing on securities trading systems. Mr.
              Jaffe graduated in 1987 from Dartmouth College with a degree in
              Psychology modified with Computer Science.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default KenBio;
