import React from "react";
import "./Hero.css";
import heroVideo from "../../assets/videos/homepage.mp4";

const Hero = props => {
  return (
    <>
      <div className="hero_container" ref={props.refProp}>
        <div className="hero_content_container">
          <video autoPlay muted loop id="hero_video">
            <source src={heroVideo} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
          <div className="hero_content_wrapper">
            <div className="hero_content">
              <h1 className="letter_spacing">A PREMIER</h1>
              <h1 className="letter_spacing">ALTERNATIVE INVESTMENT</h1>
              <h1 className="letter_spacing">ASSET MANAGER</h1>
            </div>
            <div className="hero_lower_content">
              <span className="uppercase hero_titles">
                compelling asset classes
              </span>
              <span className="hero_vertical-line">|</span>
              <span className="uppercase hero_titles">
                distinct product structures
              </span>
              <span className="hero_vertical-line">|</span>
              <span className="uppercase hero_titles">
                performance-driven management
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
