import React from "react";

import "./ContactUs.css";

import mailImg from "../../assets/images/MAIL.png";
import phoneImg from "../../assets/images/PHONE.png";
import linkedinImg from "../../assets/images/linkedin.png";

const ContactUsTest = (props) => {
  return (
    <>
      <div className="contact-container" ref={props.refProp} id="contact-us">
        <h1>CONTACT US</h1>
        <div className="contact-icon-wrapper">
          <span>
            <img src={phoneImg} alt="phone" />{" "}
            <a href="tel:+4752820861">475-282-0861</a>
          </span>
          <span>
            <img src={mailImg} alt="email" />{" "}
            <a href="mailto:info@scfundmanagement.com">
              info@scfundmanagement.com
            </a>
          </span>
          <span>
            <a
              href="https://www.linkedin.com/company/strategiccapitalfundmanagement/
"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={linkedinImg}
                alt="linkedin"
                className="contact-social-icon"
              />
            </a>
          </span>
        </div>
        <p className="contact-copyright">
          © 2019 Copyright All Rights Reserved | Strategic Capital Fund
          Management, LLC
        </p>
      </div>
    </>
  );
};

export default ContactUsTest;
